import React, { useState, useEffect } from "react"
import { useQuery, gql } from '@apollo/client'

import ProductCard from '../ProductCard/ProductCard'
import { getFluidImage } from '../../helpers/apollo'

const RelatedProducts = ( props ) => {

	const { id, brandName, subCategory } = props
	const limit = 4 // number of products wanted to be shown
	const max = 500

	const where = { id_nin: [id] , brand: { name : brandName }  }
	const [ variables, setVarialbles ] = useState({ limit: limit, where: where })
	const [ state, setState ] = useState({ products: [] })
	const [ ids, setIds ] = useState([ id ])

	const productQuery = gql`
		query getRelatedProducts ( $limit: Int, $where: JSON) {
			products ( limit: $limit, where: $where) {
				id
				name
				price
				slug
				includeInSale
				sale {
					discount
					amount
				}
				images {
					url
					formats
					width
					height
				}
				colour_option {
					hexValue
					name
					id
					colour_category {
						id
						colour
					}
				}
				relatedProducts {
					products {
						id
						slug
						colour_option {
							hexValue
							id
							name
						}
					}
				}
			}
		}
	`
	
	let { error, data } = useQuery( productQuery,
		{
			variables: {
				...variables
			},
			fetchPolicy: "cache-and-network"
		}
	);

	useEffect(() => {

		if ( data ) {
			let updatedProducts = state.products

			data.products.forEach( product => {

				if ( ids.includes( product.id ) ) return

				product.images[0].imageFile =  { childImageSharp : { fluid : getFluidImage( product, max ) } } 
				ids.push( product.id )
				updatedProducts.push( product )
			})

			if ( updatedProducts.length < limit ) {
				if ( variables.where.brand ) {
					setVarialbles( {limit: limit, where: { id_nin: ids, sub_category: { label: subCategory } }})
				} else if ( variables.where.sub_category ) {
					setVarialbles( {limit: limit, where: { id_nin: ids }})
				}
				setState({ products: updatedProducts })
			} else {
				updatedProducts.length = limit
				setState({ products: updatedProducts })
			}

			setIds( ids )
		}
	}, [ data, ids, state.products, subCategory, variables ])

	if ( data ) {
		// makes the data mutable
		data = JSON.parse( JSON.stringify( data ) )
	}

	if ( error ) return null;

	return (
		<>
			{ state && !!state.products.length &&
				<div className="related-products">
					<div className="related-products__heading-container">
						<h2 className="related-products__heading">You may also like</h2>
					</div>
					<div className="related-products__wrapper">
						{ state.products.map( ( node , i ) => (
							<React.Fragment key={ i }>
								<ProductCard product={ node } />
							</React.Fragment>
						) ) }
					</div>
				</div>
			}
		</>
	)
}

export default RelatedProducts