import React, { useState, useContext } from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown"

import { CartContext } from '../context/CartContext'
import { formatPrice } from '../helpers/currency'

import RelatedProducts from "../components/RelatedProducts/RelatedProducts"
import SEO from '../components/SEO/Seo'
import Chevron from '../images/svgs/chevron.svg';

import GiftVoucher10 from '../images/svgs/gift-voucher-10.svg'
import GiftVoucher20 from '../images/svgs/gift-voucher-20.svg'
import GiftVoucher25 from '../images/svgs/gift-voucher-25.svg'
import GiftVoucher30 from '../images/svgs/gift-voucher-30.svg'
import GiftVoucher50 from '../images/svgs/gift-voucher-50.svg'
import GiftVoucher75 from '../images/svgs/gift-voucher-75.svg'
import GiftVoucher100 from '../images/svgs/gift-voucher-100.svg'
import GiftVoucher150 from '../images/svgs/gift-voucher-150.svg'
import MakeoverVoucher from '../images/svgs/makeover-voucher.svg'
import DiamondVoucher from '../images/svgs/diamond-voucher.svg'

export default ({  data: { strapi: { vouchersValue }}, location, pageContext }) => {

	const product = pageContext.giftVoucher ? vouchersValue.standardVouchers : vouchersValue.makeoverDayVouchers
	const name = pageContext.giftVoucher ? "Gift Voucher" : "Makeover Day Voucher"

	const { addToCart } = useContext( CartContext );
	const [ quantity, setQuantity ] = useState( 1 );
	const [ activeGiftCard, setActiveGiftCard ] = useState( product.voucherValues[0] )
	const [ isActive, setIsActive ] = useState( false )

	const metaDescription = product.voucherDescription ? product.voucherDescription.replace(/(\r\n|\n|\r)/gm, " ").substring(0, 170) : null
	const metaImage = product.images ? {
		src: product.images[0].imageFile.childImageSharp.fluid.src,
		height: 500,
		width: 500,
	} : null


	const incrementQuantity = ( increment ) => {
		const incrementedQuantity = quantity + increment;

		if ( incrementedQuantity ) {
			setQuantity( incrementedQuantity );
		}
	}

	const handleAddToCart = (product, quantity) => {
		product.slug = pageContext.slug
		product.giftCard = true
		product.makeoverDayVoucher = !pageContext.giftVoucher

		addToCart( product, quantity, product.value )
	}

	const handlePriceFilter = ( giftCard ) => {
		setActiveGiftCard( giftCard )
		setIsActive( false )
	}

	const renderImage = activeGiftCard => {
		const { value, isMakeoverVoucher, isDiamondVoucher } = activeGiftCard

		if ( isMakeoverVoucher && isDiamondVoucher ) {
			return <DiamondVoucher className="gift-voucher__image" />
		} else if ( isMakeoverVoucher && !isDiamondVoucher ) {
			return <MakeoverVoucher className="gift-voucher__image" />
		}

		switch ( value ) {
			case 10:
				return <GiftVoucher10 className="gift-voucher__image" />
			case 20:
				return <GiftVoucher20 className="gift-voucher__image" />
			case 25:
				return <GiftVoucher25 className="gift-voucher__image" />
			case 30:
				return <GiftVoucher30 className="gift-voucher__image" />
			case 50:
				return <GiftVoucher50 className="gift-voucher__image" />
			case 75:
				return <GiftVoucher75 className="gift-voucher__image" />
			case 100:
				return <GiftVoucher100 className="gift-voucher__image" />
			case 150:
				return <GiftVoucher150 className="gift-voucher__image" />
			default:
				return <GiftVoucher10 className="gift-voucher__image" />
		}
	}

	return (
		<>
			<SEO
				title={ name }
				description={ metaDescription }
				image={ metaImage }
				pathname={ location.pathname } />

			<div className="wrapper">
				<div className="product-template product-template--gift-voucher">
					<div className="product-template__main-section">
						<div className="product-template__image">
							<div className="product-images">
								<div className="product-images__main">
									{ renderImage( activeGiftCard ) }
								</div>
							</div>
						</div>

						<div className="product-template__info">
							<h1 className="product-template__name">{ name }</h1>

							{ product.voucherDescription && <ReactMarkdown source={ product.voucherDescription } className="product-template__description" /> }

							<div className="gift-voucher__selection">
								<button className="gift-voucher__price" onClick={ () => setIsActive( !isActive ) }> 
									<span className="">{ `${ activeGiftCard.valueLabel } - ${ formatPrice( activeGiftCard.value ) }` }</span>
									<Chevron className={`gift-voucher__chevron ${ isActive ? "active" : "" }`} />
								</button>

								{ product.voucherValues &&
									<ul className={`gift-voucher__filter-list ${ isActive ? "active" : ''}` }>
										{ product.voucherValues.map( ( giftCard, i ) => (
											<li key={ i } className={`gift-voucher__filter-item ${ activeGiftCard.value === giftCard.value ? 'active' : '' }`}
												>
												<button className="filters__filter-item-button" onClick={ () => handlePriceFilter( giftCard ) }>{ `${ giftCard.valueLabel } - ${ formatPrice( giftCard.value ) }` }</button>
											</li>
										) ) }
									</ul>
								}
							</div>

							<div className="product-template__size-container">
								<Link className={`product-template__size ${ pageContext.giftVoucher ? 'selected' : ''}`} to="/products/gift-vouchers">
									gift vouchers
								</Link>
								<Link className={`product-template__size ${ !pageContext.giftVoucher ? 'selected' : ''}`} to="/products/makeover-day-vouchers">
									Makeover day
								</Link>
							</div>

							<div className="product-template__inputs-container">
								<button
									className="button button--no-margin"
									onClick={ () => handleAddToCart( activeGiftCard, quantity ) }>
										Add to Bag
								</button>
								<div className="product-template__quantity-container">
									<button className={`product-template__quantity-trigger product-template__quantity-trigger--decrease ${ quantity === 1  ? 'disabled' : '' }`} onClick={ () => incrementQuantity( -1 ) }>
										_
									</button>
									<div className="product-template__quantity-input">
										{ quantity }
									</div>
									<button className={`product-template__quantity-trigger`} onClick={ () => incrementQuantity( 1 ) }>
										+
									</button>
								</div>
							</div>

						</div>
					</div>
				</div>

				<RelatedProducts />
			</div>
		</>
	)
}

export const query = graphql`
	query  giftcardQuery {
		strapi {
			vouchersValue {
				makeoverDayVouchers {
					id
					voucherDescription
					voucherValues {
						id
						value
						valueLabel
						isMakeoverVoucher
						isDiamondVoucher
					}
				}

				standardVouchers {
					id
					voucherDescription
					voucherValues {
						id
						value
						valueLabel
						isMakeoverVoucher
						isDiamondVoucher
					}
				}
			}
		}
	}
`